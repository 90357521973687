import React from "react"
import { Layout } from "../components"

export default function Blog() {
  return (
    <Layout>
      <div className="container blog">
        <h1 className="title as-h1">Comming Soon</h1>
      </div>
    </Layout>
  )
}
