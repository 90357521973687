import * as React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import logo from "../images/logo.jpg"

import { FaSearch } from "react-icons/fa"

export const NavBar = () => {
  const [isActive, setIsActive] = React.useState(false)
  const result = useStaticQuery(graphql`
    {
      datoCmsMenu {
        menuItems {
          originalId
          labelText
          destination {
            slug
          }
        }
      }
    }
  `)
  const menuItems = result.datoCmsMenu.menuItems

  return (
    <div className="main-nav">
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item logo" title="Logo">
              <img
                src={logo}
                alt="Nahy"
                className="logo"
                style={{ width: "110px", paddingLeft: "0.5rem" }}
              />
            </Link>
            {/* Hamburger menu */}
            <button
              className={`navbar-burger burger ${isActive && "is-active"}`}
              aria-expanded={isActive}
              onClick={() => setIsActive(!isActive)}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <ul
            id="navMenu"
            className={` navbar-end has-text-centered navbar-menu ${
              isActive && "is-active"
            }`}
          >
            <div className="navbar-item">
              <p className="control has-icons-left">
                <input
                  className="input is-rounded"
                  type="email"
                  placeholder="tìm kiếm..."
                />
                <span className="icon is-small is-left">
                  <FaSearch />
                </span>
              </p>
            </div>

            {menuItems.map(menuItem => (
              <li
                className="navbar-item"
                style={{ padding: "0px" }}
                key={menuItem.originalId}
              >
                <Link
                  className="navbar-item"
                  style={{ fontFamily: "Prata" }}
                  to={
                    menuItem.destination.slug === "trang-chu"
                      ? "/"
                      : `/${menuItem.destination.slug}`
                  }
                >
                  {menuItem.labelText}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  )
}

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}
