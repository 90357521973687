import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

export const Footer = () => {
  const {
    datoCmsHomePage: { secondSection },
  } = useStaticQuery(graphql`
    {
      datoCmsHomePage {
        secondSection {
          groupProductName
        }
      }
    }
  `)
  console.log(secondSection)
  return (
    <div className="main-footer">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <h3>Liên hệ</h3>
            <p>Công ty TNHH Nahy</p>
            <p>123 Nguyễn Văn Trỗi</p>
            <p>Phường 3, Quận Phú Nhuận, TP Hồ Chính Minh</p>
            <p>Email: nahy@nahytranding.com</p>
            <p>Modile: 0983 52 33 62</p>
          </div>
          <div className="column">
            <h3>Sản phẩm</h3>
            {secondSection.map(groupProduct => (
              <>
                <Link to="/">{groupProduct.groupProductName}</Link>
                <br />
              </>
            ))}
          </div>
          <div className="column">
            <h3>Chính sách khách hàng</h3>
            <p>Chính sách giao hàng</p>
            <p>Chính sách bảo hành</p>
          </div>
        </div>
      </div>
    </div>
  )
}
